<template>
  <div id="newsStoryContent">
    <p class="center" id="Text1">
      <!-- pic13A1A9BBC739221CBA3AEBD4FC09922A.jpg -->
      <img src="@/assets/news-network/contest-battle-of-ns.jpg" class="rounded">
    </p>
    <p>
      <i>
        It’s all happening! I mean – it’s already happened! No… it’s already about to have happened! …I think. Nimbus Station’s past is under attack, and if we don’t return it to its normal present in the very near future, our failure will lead to the unraveling of LEGO Universe!
      </i>
    </p>
    <p>
      If my patented Ultra Transchrono Noggulattor is to be trusted, then there may be a secret source of Maelstrom passing through the quantum scaffolding of space time, allowing it to seep into the fabric of our past. If readings are to be believed, seventeen googletons of chronomorphular Maelstrom could be headed – er, headING – straight for the pivotal historic Battle of Nimbus Station! If they aren’t stopped, they could change the past, forever mucklifying our plane of existence!
    </p>
    <p>
      Wait a tick… if you warp back to the battle and force the Maelstrom out of that timeline, it will be forced back through its time warpation pathway… thus recompromising the properties of plastonic mechanics! Eureka! Our timeline will be saved!
    </p>
    <p>
      There’s no time for dawdling, Nexus Force!
    </p>
    <p>
      <i>- Wenn Wuzzit</i>
    </p>
    <p>
      You heard the minifigure! Help Wenn Wuzzit enforce the laws of brickular metaphysics by completing the Battle of Nimbus Station minigame as quickly as possible between Saturday, August 13th and Sunday, August 14th! The top twenty players who pass all 29 stages the fastest will win stellar in-game items and a timeless collection of LEGO Kingdoms sets that the whole court will love!
    </p>
    <p class="center">
      <!-- picC829F64408A37FDCAB95E51DE412ECD7.jpg -->
      <img src="" class="rounded">
    </p>
    <p>
      The top winners from each age group will receive:
      <br>o	<a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=7189&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Mill Village Raid set (#7189)</a>
      <br>o	<a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=7188&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms King’s Carriage Ambush set (#7188)</a>
      <br>o	<a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=7187&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Escape from the Dragon’s Prison set (#7187)</a>
      <br>o	<a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=6918&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Blacksmith Attack set (#6918)</a>
      <br>o	1 in-game 4 Riders Shirt (+4 Imagination)
      <br>o	1 in-game Nexus Force Shield MkIII (+5 Armor, +3 Imagination)
      <br>o	1 in-game Snazzy Pants (+3 Armor, +3 Imagination)
      <br>o	75 Faction Tokens
      <br>o	1 in-game Clear Gem (worth 50,000 coins)
    </p>
    <p>
      The next 3 winners in each age group will get:
      <br>o	<a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=7188&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms King’s Carriage Ambush set (#7188)</a>
      <br>o	<a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=7187&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Escape from the Dragon’s Prison set (#7187)</a>
      <br>o	<a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=6918&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Blacksmith Attack set (#6918)</a>
      <br>o	1 in-game Kinga Hurl Shirt (+3 Imagination)
      <br>o	1 in-game Nexus Force Shield MkII (+4 Armor, +2 Imagination)
      <br>o	1 in-game Jazzy Pants (+2 Armor, +2 Imagination)
      <br>o	50 Faction Tokens
      <br>o	1 in-game Dark Blue Gem (worth 10,000 coins)
    </p>
    <p>
      The next 6 winners in each age group will receive:
      <br>o	<a href="https://web.archive.org/web/20110831225941/http://shop.lego.com/product/?p=6918&amp;LangId=2057&amp;ShipTo=US">1 LEGO Kingdoms Blacksmith Attack set (#6918)</a>
      <br>o	1 in-game Murgle Blotch Shirt (+2 Imagination)
      <br>o	1 in-game Nexus Force Shield MkI (+4 Armor)
      <br>o	1 in-game Zazzy Pants (+1 Armor, +1 Imagination)
      <br>o	50 Faction Tokens
      <br>o	1 in-game Dark Red Gem (worth 5,000 coins)
    </p>
    <p>
      <b>The Details!</b>
    </p>
    <p>
      If you play on the Overbuild server (US), you must play the Battle of Nimbus Station mini-game between 12:01 AM EST Saturday, August 13th and 11:59 PM EST Sunday, August 14th.
    </p>
    <p>
      If you play on the Storm server (UK), you must play the Battle of Nimbus Station mini-game between 00:01 GMT Saturday, August 13th and 23:59 GMT Sunday, August 14th.
    </p>
    <p>
      If you play on the Exeter server (Germany), you must play the Battle of Nimbus Station mini-game between 00:01 CET Saturday, August 13th and 23:59 CET Sunday, August 14th.
    </p>
    <p>
      We will draw the top 20 winners, across all 3 servers, based on the speed at which they make it through wave 29. The in-game leader boards will not reflect the 20 winners.
    </p>
    <p>
      Play as much as you want during the weekend! The top 20 winners will get their LEGO Sets in the regular mail whereas the rest of the prizes will be distributed via in-game email.
    </p>
    <p>
      Discuss this competition, and the new age group-specific prizes, on the
      <router-link to="/messageboards/4113178">forums</router-link>!
    </p>
  </div>
</template>
